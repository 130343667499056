import { cateringRequest, cateringRequestHide, cateringUploadsHide } from '@/utils/fetch'
import { uploadFileItem } from './base'

interface saveReq {
  id?: string
  project_id: string
  money: string
  explain?: string // 电话
  attachment: string // 附件
  name: string //附件名称
  size: string //附件大小
  pics?: Array<uploadFileItem> // 图片
}

export type saveProject = saveReq //导出请求保存的数据结构

// 施工申请 添加接口
export function AgreementSave(data: saveReq) {
  const url = '/api/agreement/save'
  return cateringRequest.post(url, data)
}
